import React, { FC } from "react";
import styled from "styled-components";
import { breakpoints } from "@theme/breakpoints";
import HtmlParser from "react-html-parser";

const StyledWrapper = styled.div`
  display: flex;
  align-items: flex-start;

  svg,
  img {
    width: 70px;
    height: auto;
  }

  ${breakpoints.laptopMax`
    svg,
    img {
      width: 60px;
    }
  `}

  ${breakpoints.phoneMax`
    flex-direction: column;
    align-items: center;
    
    svg,
    img {
      width: 50px;
    }
  `}
`;

const StyledItemRightWrapper = styled.div`
  flex: 1;
  margin-left: 40px;

  ${breakpoints.phoneMax`
    margin-left: 0;
    margin-top: 30px;
  `}
`;

const StyledItemHeadline = styled.h3`
  margin: 0 0 20px;
  font-size: 2.8rem;
  font-weight: 700;

  ${breakpoints.laptopMax`
    font-size: 2.4rem;
  `}

  ${breakpoints.phoneMax`
    text-align: center;
  `}
`;

const StyledItemDescription = styled.p`
  font-size: 1.8rem;
  line-height: 1.6;
  margin: 0;

  ${breakpoints.laptopMax`
    font-size: 1.6rem;
  `}
`;

export const ImageTextItem: FC<Props> = ({
  vector: Vector,
  name,
  description,
  className,
}) => (
  <StyledWrapper className={className}>
    {typeof Vector !== "string" ? <Vector /> : <img src={Vector} alt="" />}
    <StyledItemRightWrapper>
      <StyledItemHeadline>{name}</StyledItemHeadline>
      <StyledItemDescription>{HtmlParser(description)}</StyledItemDescription>
    </StyledItemRightWrapper>
  </StyledWrapper>
);

interface Props {
  vector: FC | string;
  name: string;
  description: string;
  className?: string;
}
