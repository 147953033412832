import React, { FC } from "react";
import styled from "styled-components";
import { BasicTemplate } from "@templates/BasicTemplate";
import { graphql } from "gatsby";
import { breakpoints } from "@theme/breakpoints";
import { stylesWrapper, wysiwygStyles } from "@theme/commonStyles";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { stripHtml } from "@utils/stripHtml";
import { WhyWorkInRendPro } from "@components/molecules/WhyWorkInRendPro";
import { BackButton } from "@components/atoms/BackButton";
import { Routes } from "@routes";

const StyledWrapper = styled.div`
  ${stylesWrapper};
  margin-top: 220px;

  ${breakpoints.tabletMax`
    margin-top: 180px;
  `}

  ${breakpoints.phoneMax`
    margin-top: 140px;
  `}
`;

const StyledName = styled.h1`
  font-size: 4.8rem;
  margin: 30px 0 52px;

  ${breakpoints.tabletMax`
    font-size: 4.2rem;
    margin: 20px 0 40px;
  `}

  ${breakpoints.phoneMax`
    font-size: 3.2rem;
    margin: 20px 0 30px;
  `}
`;

const StyledContentWrapper = styled.div`
  font-size: 2rem;

  * {
    max-width: 800px;
    font-family: "Open Sans", "Helvetica Neue", "Roboto", sans-serif !important;
  }

  img,
  iframe {
    max-width: unset;
    width: 100%;
    border-radius: 40px;
    margin: 60px 0;
  }

  iframe {
    height: 70vw;
    max-height: 450px;
  }

  ${wysiwygStyles}

  h2:first-of-type {
    margin-top: 0;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 700;
    margin-left: 0 !important;
  }

  h2 {
    font-size: 4rem;
    margin: 60px 0 40px;
  }

  h3 {
    font-size: 3.2rem;
    margin: 60px 0 30px;
  }

  h4 {
    font-size: 2.8rem;
    margin: 50px 0 30px;
  }

  h5 {
    font-size: 2.4rem;
    margin: 50px 0 20px;
  }

  h6 {
    font-size: 2rem;
    text-transform: uppercase;
    margin: 40px 0 20px;
  }

  figure {
    margin: 0;
  }

  blockquote {
    font-size: 2.8rem;
    font-weight: 600;
    line-height: 1.6;
    position: relative;
    max-width: unset;
    width: 100%;
    margin: 50px 0;
    padding-left: 40px;

    ::before {
      left: 0;
      top: 0;
      height: 100%;
      width: 4px;
      background: ${({ theme }) => theme.primary};
      border-radius: 2px;
      position: absolute;
      content: "";
    }
  }

  ${breakpoints.phoneMax`
    font-size: 1.6rem;

    img, iframe {
      max-width: unset;
      width: 100%;
      border-radius: 20px;
      margin: 30px 0;
    }


    h2 {
      font-size: 2.8rem;
      margin: 40px 0 20px;
    }

    h3 {
      font-size: 2.4rem;
      margin: 40px 0 20px;
    }

    h4 {
      font-size: 2rem;
      margin: 30px 0 10px;
    }

    h5 {
      font-size: 1.8rem;
      margin: 30px 0 10px;
    }

    h6 {
      font-size: 1.6rem;
      text-transform: uppercase;
      margin: 30px 0 10px;
    }

    blockquote {
      font-size: 2rem;
      font-weight: 600;
      line-height: 1.6;
      margin: 30px 0;
      padding-left: 20px;

      ::before {
        width: 2px;
        border-radius: 2px;
      }
    }
  `}
`;

const BlogTemplate: FC<{ pageContext: { id: string }; data: Data }> = ({
  data,
}) => {
  const { t: ct } = useTranslation("common");
  const { name, longDescription, seoTitle, seoDescription } =
    data.strapiCareer || data.alternativeStrapiCareer;

  const title =
    seoTitle && seoTitle.length > 0 ? seoTitle : `${name} | ${ct("title")}`;
  const description =
    seoDescription && seoDescription.length > 0
      ? seoDescription
      : stripHtml(longDescription.data.longDescription);

  return (
    <BasicTemplate title={title} description={seoDescription} withoutNewsletter>
      <StyledWrapper>
        <BackButton to={Routes.Career}>{ct("back-button-career")}</BackButton>
        <StyledName>{name}</StyledName>
        <StyledContentWrapper
          dangerouslySetInnerHTML={{
            __html: longDescription.data.longDescription,
          }}
        />
      </StyledWrapper>
      <WhyWorkInRendPro />
    </BasicTemplate>
  );
};

interface Data {
  strapiCareer: StrapiCareer;
  alternativeStrapiCareer: StrapiCareer;
}

interface StrapiCareer {
  name: string;
  seoTitle?: string;
  seoDescription?: string;
  longDescription: {
    data: {
      longDescription: string;
    };
  };
}

export const query = graphql`
  query ($language: String!, $strapi_id: Int!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    strapiCareer(locale: { eq: $language }, strapi_id: { eq: $strapi_id }) {
      name
      seoTitle
      seoDescription
      longDescription {
        data {
          longDescription
        }
      }
    }

    alternativeStrapiCareer: strapiCareer(
      locale: { eq: $language }
      localizations: { data: { elemMatch: { id: { eq: $strapi_id } } } }
    ) {
      name
      seoTitle
      seoDescription
      longDescription {
        data {
          longDescription
        }
      }
    }
  }
`;

export default BlogTemplate;
