import React from "react";
import styled from "styled-components";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { SectionHeadline } from "@components/atoms/SectionHeadline";
import { stylesWrapper } from "@theme/commonStyles";
import { breakpoints } from "@theme/breakpoints";
import { ReactComponent as Clock } from "@vectors/clock.svg";
import { ReactComponent as Teamwork } from "@vectors/teamwork.svg";
import { ReactComponent as WorldWide } from "@vectors/worldwide.svg";
import { ReactComponent as Equality } from "@vectors/equality.svg";
import { ReactComponent as Development } from "@vectors/development.svg";
import { ReactComponent as Controller } from "@vectors/controller.svg";
import { ImageTextItem } from "@components/atoms/ImageTextItem";

const StyledWrapper = styled.section`
  ${stylesWrapper};
  margin: 160px auto 0;

  ${breakpoints.laptopMax`
    margin: 120px auto 0;
  `}

  ${breakpoints.tabletMax`
    margin: 100px auto 0;
  `}

  ${breakpoints.phoneMax`
    margin: 80px auto 0;
  `}
`;

const StyledHeadline = styled(SectionHeadline)`
  margin-bottom: 120px;

  ${breakpoints.tabletMax`
    margin-bottom: 80px;
  `}

  ${breakpoints.phoneMax`
    margin-bottom: 60px;
    text-align: left;
  `}
`;

const StyledItemsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  ${breakpoints.tabletMax`
    flex-direction: column;
  `}
`;

const StyledItem = styled(ImageTextItem)`
  margin-bottom: 90px;
  width: 48%;
  max-width: 590px;

  ${breakpoints.tabletMax`
    width: 100%;
    max-width: unset;
    margin-bottom: 60px;
  `}

  ${breakpoints.phoneMax`
    margin-bottom: 40px;
  `}
`;

const ITEMS_SVG = [
  Clock,
  Teamwork,
  WorldWide,
  Equality,
  Development,
  Controller,
];

export const WhyWorkInRendPro = () => {
  const { t } = useTranslation("why-work-in-rendpro");

  const items: { name: string; description: string }[] = t("items", {
    returnObjects: true,
  });

  return (
    <StyledWrapper>
      <StyledHeadline dangerouslySetInnerHTML={{ __html: t("title") }} />

      <StyledItemsWrapper>
        {items.map(({ name, description }, index) => {
          const Vector = ITEMS_SVG[index];

          return (
            <StyledItem
              key={name}
              vector={Vector}
              name={name}
              description={description}
            />
          );
        })}
      </StyledItemsWrapper>
    </StyledWrapper>
  );
};
